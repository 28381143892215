.ripple-animation {
  animation: ripple 0.6s linear infinite;
}

@-webkit-keyframes ripple {
  0% {
    box-shadow:
      0 4px 10px rgba(255, 255, 255, 0.1),
      0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 5px rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 65, 130, 0.1);
  }
  100% {
    box-shadow:
      0 4px 10px rgba(255, 255, 255, 0.1),
      0 0 0 5px rgba(255, 65, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 65, 130, 0);
  }
}
@keyframes ripple {
  0% {
    box-shadow:
      0 4px 10px rgba(255, 255, 255, 0.1),
      0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 5px rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    box-shadow:
      0 4px 10px rgba(255, 255, 255, 0.1),
      0 0 0 5px rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0);
  }
}
